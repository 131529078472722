.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header {

    & > button {
        left: 16px;
    }
}

.chat {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.container {
    padding: 0 16px;
}

.call {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;

    &_button {
        min-height: 148px;
        width: 148px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F2F4F7;
        border-radius: 20px;
        box-shadow: 0px 30px 30px -20px rgba(0, 0, 0, 0.2);
        cursor: pointer;
    
        transition: background-color 100ms;
    
        &:hover {
            background-color: #D8E7FE;
    
            & > p {
                color: #2868C8;
            }
        }

        &_title {
            position: absolute;
            height: 20px;
            margin-top: 90px;
            color: #9CA7B5;
            font-size: 12px;
            font-family: 'Inter-SemiBold';
        }
    }

    &_title {
        margin-top: 40px;
        color: #24242E;
        font-size: 18px;
        font-family: 'Inter-SemiBold';
    }

    &_subtitle {
        color: #9CA7B5;
        font-size: 12px;
        font-family: 'Inter-Regular';
        margin-top: 12px;
    }
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 100;

    animation-name: backgroundColorAnimation;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
}

.modal {
    height: 100%;
    background-color: #FFFFFF;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    transform: translateY(100%);

    animation-name: translateYAnimation;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
}

@keyframes backgroundColorAnimation {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

@keyframes translateYAnimation {
    from {
        transform: translateY(100%);
    }
  
    to {
        transform: translateY(0%);
    }
}

.container {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.status {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0 50px 0;

    &_checked {
        display: flex;
        flex-direction: column;
        align-items: center;
    
        &::before {
            content: '';
            position: absolute;
            height: 25px;
            width: 25px;
            border: none;
            background-image: url("../../../../assets/icons/checkbox-checked.svg");
            background-size: 50% 50%;
            background-repeat: no-repeat;
            background-position: center center;
        }

        &_indicator {
            height: 25px;
            width: 25px;
            border: 3px solid #C0D9FF;
            border-radius: 50%;
            background-color: #C0D9FF;
        }
    }

    &_unchecked {
        display: flex;
        flex-direction: column;
        align-items: center;

        &_indicator {
            height: 25px;
            width: 25px;
            border: 3px solid #C0D9FF;
            border-radius: 50%;
        }
    }

    &_declined {

        &_indicator {
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 3px solid #DF4759;
            border-radius: 50%;
            background-color: #DF4759;
        
            &::before {
                content: '';
                position: absolute;
                height: 15px;
                width: 2px;
                background-color: #fff;
                transform: rotate(45deg);
            }
        
            &::after {
                content: '';
                position: absolute;
                height: 15px;
                width: 2px;
                background-color: #fff;
                transform: rotate(-45deg);
            }
        }
    }

    &_separator {
        position: absolute;
        top: 12px;
        height: 1px;
        width: 87px;
        background-color: #C0D9FF;
    }

    &_title {
        position: absolute;
        top: 35px;
        color: #24242E;
        font-size: 12px;
        font-family: 'Inter-SemiBold';
    }
}

.price {
    display: block;
    max-width: 343px;
    width: 100%;
    margin: 0 auto 16px auto;
    color: #24242E;
    font-family: 'Inter-SemiBold';
    font-size: 24px;
}

.button {
    height: 53px;
    max-width: 343px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 16px auto;
    border-radius: 10px;
    background-color: #2868C8;
    color: #FFFFFF;
    font-family: 'Inter-SemiBold';
    font-size: 18px;

    transition: background-color 100ms;

    &:hover {
        color: #2868C8;
        background-color: #D8E7FE;
    }
}

.indicator {
    top: -5px;
    right: -5px;
    width: 15px;
    height: 15px;
}

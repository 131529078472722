.switch-wrapper {
	position: relative;
	height: 100%;

	& > div {
		position: absolute;
		height: 100%;
		width: 100%;
	}
}

.container {
    width: 100%;
    height: 347px * 0.6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 16px;
    margin-left: 16px;
    border-radius: 20px;
    background-color: #F2F4F7;
    box-shadow: 0px 15px 30px -22px rgba(0, 0, 0, 0.3);

    transition: all 100ms;

    &:hover {
        background-color: #D8E7FE;
    }

    &:active {
        opacity: 0.7;
    }

    @media screen and (min-width: 348px) {
        width: calc(50% - 16px);
        height: 150px;
    }

    @media screen and (min-width: 515px) {
        width: calc(33.3333333333% - 16px);
    }

    @media screen and (min-width: 680px) {
        width: calc(25% - 16px);
    }

    @media screen and (min-width: 846px) {
        width: calc(20% - 16px);
        max-width: 192px;
    }
}

.title {
    margin-top: 13px;
    color: #2868C8;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
}

.overlay {
    padding-top: 32px;
}

.modal {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
}

.container {
    flex: 1;
    overflow-y: auto;
    padding: 16px 0 120px 0;
}

.title {
    margin-top: 32px;
    color: #3D3D3D;
    font-family: 'Inter-SemiBold';
    font-size: 18px;
}

.input {
    min-height: 50px;
    max-height: 300px;
    width: 100%;
    display: block;
    padding-top: 16px;
    resize: vertical;
    border-bottom: 1px solid rgba(89, 29, 71, 0.1);
    color: #24242E;
    font-family: 'Inter-Regular';
    font-size: 14px;
    user-select: text;

    &_symbols-count {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > p {
            color: #9CA7B5;
            font-family: 'Inter-Regular';
            font-size: 12px;
        }
    }
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    padding: 16px 16px 32px 16px;

    &_button-confirm {
        height: 56px;
        max-width: 345px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background-color: #2868C8;
        color: #FFFFFF;
        font-family: 'Inter-SemiBold';
        font-size: 18px;
    
        transition: background-color 100ms;
    
        &:hover {
            color: #2868C8;
            background-color: #D8E7FE;
        }
    }
}

@keyframes backgroundColorAnimation {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

@keyframes translateYAnimation {
    from {
        transform: translateY(100%);
    }
  
    to {
        transform: translateY(0%);
    }
}

.container {
    align-items: center;
    justify-content: center;
}

.title {
    color: #9CA7B5;
    font-size: 100px;
}

.subtitle {
    text-transform: uppercase;
    color: #9CA7B5;
    font-family: 'Inter-Bold';
    font-size: 14px;
}

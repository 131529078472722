.overlay {
    padding-top: 32px;
}

.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 16px;
}

.icon {
    height: 72px;
    width: 72px;
    margin-bottom: 30px;
    border-radius: 50%;
    background-color: #D8E7FE;

    &::before {
        content: '';
        height: 72px;
        width: 72px;
        position: absolute;
        margin-top: 1px;
        margin-left: 3px;
        border: none;
        background-image: url("../../../assets/icons/checkbox-checked.svg");
        background-size: 70% 50%;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.title {
    color: #24242E;
    font-size: 18px;
    font-family: 'Inter-SemiBold';
}

.subtitle {
    margin-top: 16px;
    color: #9CA7B5;
    font-size: 12px;
    font-family: 'Inter-Regular';
}

.button {
    height: 56px;
    max-width: 172px;
    width: 100%;
    margin-top: 40px;
    border-radius: 10px;
    background-color: #2868C8;
    color: #FFFFFF;
    font-size: 18px;
    font-family: 'Inter-SemiBold';

    transition: background-color 100ms;

    &:hover {
        background-color: #D8E7FE;
        color: #2868C8;
    }
}

@keyframes backgroundColorAnimation {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

@keyframes translateYAnimation {
    from {
        transform: translateY(100%);
    }
  
    to {
        transform: translateY(0%);
    }
}

.container {
    padding: 0 16px 16px 16px;
}

.image {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    object-position: center center;
    display: block;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(102, 137, 163, 0.5);

    &_container {
        position: relative;
        max-width: 960px;
        margin: 0 auto;
        border-radius: 20px;
    }

    &_title {
        position: absolute;
        top: 16px;
        left: 32px;
        padding: 4px 8px;
        border-radius: 6px;
        background-color: #FFFFFF;
        color: #24242E;
        font-family: 'Inter-SemiBold';
        font-size: 14px;
    }
}

.title {
    margin-top: 32px;
    color: #000000;
    font-family: 'Inter-SemiBold';
    font-size: 24px;
}

.info {
    display: flex;
    margin: 16px 0;

    &_price {
        color: #24242E;
        font-family: 'Inter-SemiBold';
        font-size: 18px;
    }

    &_weight {
        margin-left: 20px;
        color: #9CA7B5;
        font-family: 'Inter-SemiBold';
        font-size: 18px;
    }
}

.description {
    color: #616B78;
    font-family: 'Inter-Medium';
    font-size: 14px;
}

.footer {
    position: fixed;
    top: 58px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    padding: 16px;

    animation-duration: 300ms;
    animation-name: translateYAnimation;

    &_quantity {
        width: 40px;
        color: #332B30;
        font-family: 'Inter-Bold';
        font-size: 18px;
        text-align: center;
    }

    &_buttons-quantity {
        max-width: 172px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 10px;
        border-radius: 10px;

        &_button {
            height: 100%;
            width: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            font-family: 'Inter-Regular';
            font-size: 14px;
        }
    }

    &_button-confirm {
        height: 56px;
        max-width: 172px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        border-radius: 10px;
        background-color: #2868C8;
        color: #FFFFFF;
        font-family: 'Inter-SemiBold';
        font-size: 18px;
    
        transition: background-color 100ms;
    
        &:hover {
            color: #2868C8;
            background-color: #D8E7FE;
        }
    }
}

@keyframes translateYAnimation {
    from {
        transform: translateY(-100%);
    }
  
    to {
        transform: translateY(0%);
    }
}

.container {
    position: relative;
    width: 100%;
    display: block;
    margin-left: 16px;
    margin-bottom: 24px;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(102, 137, 163, 0.5);

    @media screen and (min-width: 734px) {
        width: calc(50% - 16px);
    }

    @media screen and (min-width: 1061px) {
        width: calc(33.3333333333% - 16px);
    }

    @media screen and (min-width: 1404px) {
        width: calc(25% - 16px);
    }

    @media screen and (min-width: 1747px) {
        width: calc(20% - 16px);
    }

    @media screen and (min-width: 2090px) {
        width: calc(16.6666666667% - 16px);
    }
}

.image {
    height: calc(100% - 88px);
    width: 100%;
    display: block;
    overflow: hidden;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
}

.footer {
    padding: 0 10px;
    
    &_title{
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > p {
            margin: 16px 0;
            color: #24242E;
            font-family: 'Inter-SemiBold';
            font-size: 16px;
        }
    }

    &_description {
        display: flex;
        align-items: center;

        &_status {
            font-family: 'Inter-Medium';
            font-size: 16px;
        }

        &_separator {
            height: 5px;
            width: 5px;
            display: inline-block;
            margin: 0 8px;
            border-radius: 50%;
            background-color: #9CA7B5;
        }

        &_amount {
            color: #9CA7B5;
            font-family: 'Inter-Medium';
            font-size: 16px;
        }
    }
}

.indicator {
    top: -2px;
    right: -2px;
    width: 15px;
    height: 15px;
}

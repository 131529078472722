.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    z-index: 5;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    // padding-bottom: env(safe-area-inset-bottom);

    @media screen and (min-width: 600px) {
        padding: 0 15%;
        // padding-bottom: env(safe-area-inset-bottom);
    }
}

.tab {
    position: relative;
    min-height: 50px;
    min-width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &_label {
        position: absolute;
        top: 45px;
        font-family: 'Inter-Regular';
        font-size: 10px;
    }
}

.indicator {
    top: 10px;
    right: 10px;
}

.container {
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
    margin-left: 16px;
    margin-bottom: 24px;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(102, 137, 163, 0.5);

    @media screen and (min-width: 734px) {
        width: calc(50% - 16px);
    }

    @media screen and (min-width: 1061px) {
        width: calc(33.3333333333% - 16px);
    }

    @media screen and (min-width: 1404px) {
        width: calc(25% - 16px);
    }

    @media screen and (min-width: 1747px) {
        width: calc(20% - 16px);
    }

    @media screen and (min-width: 2090px) {
        width: calc(16.6666666667% - 16px);
    }
}

.image {
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
}

.header {
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
    flex-direction: column;
    padding-right: 16px;

    &_category {
        align-self: flex-start;
        padding: 4px 8px;
        border-radius: 6px;
        background-color: #FFFFFF;
        color: #24242E;
        font-family: 'Inter-SemiBold';
        font-size: 14px;
    }

    &_title {
        margin: 8px 0;
        color: #FFFFFF;
        font-family: 'Inter-SemiBold';
        font-size: 16px;
        text-shadow: 1px 1px #333333;
    }

    &_description {
        display: flex;

        & > p {
            margin-right: 6px;
            padding: 3px 8px;
            border-radius: 6px;
            background-color: rgba(0, 0, 0, 0.5);
            color: #FFFFFF;
            font-family: 'Inter-Regular';
            font-size: 14px;
        }
    }
}

.footer {
    position: absolute;
    bottom: 16px;
    height: 42px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;

    &_quantity {
        width: 100px;
        color: #FFFFFF;
        font-size: 14px;
        font-family: 'Inter-Bold';
        text-align: center;
    }

    &_buttons-quantity {
        max-width: 172px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 10px;
        border-radius: 10px;
        background-color: rgba(19, 23, 30, 0.51);
        box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.7);

        &_decrease {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 16px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            background-color: transparent;
            color: #FFFFFF;
            font-family: 'Inter-Regular';
            font-size: 14px;
        
            &:active {
                box-shadow: 25px 0px 10px -5px rgba(34, 60, 80, 0.5) inset;
        
                -webkit-box-shadow: 25px 0px 10px -5px rgba(34, 60, 80, 0.5) inset;
                -moz-box-shadow: 25px 0px 10px -5px rgba(34, 60, 80, 0.5) inse
            }
        }

        &_increase {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 16px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            background-color: transparent;
            color: #FFFFFF;
            font-family: 'Inter-Regular';
            font-size: 14px;
            text-align: right;
        
            &:active {
                box-shadow: -25px 0px 10px -5px rgba(34, 60, 80, 0.5) inset;
        
                -webkit-box-shadow: -25px 0px 10px -5px rgba(34, 60, 80, 0.5) inset;
                -moz-box-shadow: -25px 0px 10px -5px rgba(34, 60, 80, 0.5) inset;
            }
        }
    }

    &_button-confirm {
        max-width: 172px;
        width: 100%;
        margin-left: 10px;
        border-radius: 10px;
        background-color: rgba(19, 23, 30, 0.51);
        box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.7);
        color: #FFFFFF;
        font-family: 'Inter-SemiBold';
        font-size: 18px;
    
        &:active {
            box-shadow: 0px 0px 10px 5px rgba(34, 60, 80, 0.5) inset;
    
            -webkit-box-shadow: 0px 0px 10px 5px rgba(34, 60, 80, 0.5) inset;
            -moz-box-shadow: 0px 0px 10px 5px rgba(34, 60, 80, 0.5) inset;
        }
    }
}

.button {
    display: block;
    height: 56px;
    width: 100%;
    padding: 0 16px;
    cursor: pointer;
    text-align: left;
    color: #333333;
    font-size: 18px;

    transition: background-color 100ms;

    &:hover {
        color: #2868C8;
        background-color: #D8E7FE;
    }
}

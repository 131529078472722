.container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 16px 16px 0 16px;
}

.noData {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    color: #9CA7B5;
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    letter-spacing: 1px;
}

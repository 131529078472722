.container {
    max-width: 1056px;
    width: calc(100% + 16px);
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
    padding-top: 16px;

    @media screen and (min-width: 1056px) {
        margin: 0 auto;
    }
}

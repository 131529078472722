.container {
    padding: 16px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.input {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > input {
        height: 40px;
        flex: 1;
        min-width: 100px;
        display: block;
        color: #707C97;
        font-size: 16px;
        font-family: 'Inter-Regular';
        user-select: text;
    
        &::placeholder {
            opacity: 0.5;
        }
    }

    &_button {
        height: 40px;
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    
        transition: all 100ms;
    
        &:hover {
            transform: scale(1.1);
        }
    }
}

.container {
    margin-bottom: 16px;
}

.checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.checkbox + label {
    width: 100%;
    display: inline-flex;
    align-items: center;
    color: #333333;
    font-family: 'Inter-Medium';
    font-size: 18px;
    cursor: pointer;
    user-select: none;
}

.checkbox + label::before {
    content: '';
    height: 21px;
    width: 21px;
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 10px;
    border: 2px solid #62A1FF;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.checkbox:checked + label::before {
    height: 25px;
    width: 25px;
    border: none;
    background-color: #D8E7FE;
    background-image: url("../../../../assets/icons/checkbox-checked.svg");
}

@import './assets/fonts/index.scss';

:root {
    --sab: env(safe-area-inset-bottom);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	height: 100%;
}

body {
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: #FFFFFF;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button,
textarea,
input,
select,
label,
a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: none;
	outline: none;
	background-color: #FFFFFF;
	cursor: pointer;
}

a {
	text-decoration: none;
	color: #000000;
}

#root {
	height: 100%;
}

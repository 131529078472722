.outgoingMessageContainer {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 28px;
}

.outgoingMessage {
    display: flex;
    flex-direction: column;
}

.outgoingMessageText {
    align-self: flex-end;
    color: #707C97;
    font-size: 14px;
    font-family: 'Inter-Regular';
    padding: 16px;
    border-radius: 10px 10px 0 10px;
    border: 1px solid rgba(112, 124, 151, 0.25);
}

.outgoingMessageDate {
    color: #707C97;
    font-size: 14px;
    font-family: 'Inter-Regular';
    opacity: 0.7;
    padding: 5px 0;
    text-align: right;
}

.messageReadIcon {
    position: absolute;
    right: -25px;
    top: 20px;
}

.incomingMessage {
    color: #FFFFFF;
    background-color: #60A9F6;
    font-size: 14px;
    font-family: 'Inter-Regular';
    padding: 16px;
    border-radius: 0 10px 10px 10px;
    margin-right: 28px;
}

.container {
    height: 56px;
    margin-bottom: 16px;
    border-radius: 10px;
    background-color: #D8E7FE;
    color: #2868C8;
    font-family: 'Inter-SemiBold';
    font-size: 18px;

    transition: background-color 300ms;

    &:hover {
        background-color: #2868C8;
        color: #FFFFFF;
    }
}

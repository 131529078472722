.container {
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #FF0000;

    &:before {
        content: '';
        position: relative;
        display: block;
        width: 300%;
        height: 300%;
        margin-top: -100%;
        margin-left: -100%;
        border-radius: 45px;
        opacity: 0.3;
        background-color: #FF0000;
        animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0; 
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: #FF0000;
        border-radius: 15px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
        animation: pulse-dot 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    }
}

@keyframes pulse-ring {
    0% {
        transform: scale(0.33);
    }
    80%, 100% {
        opacity: 0;
    }
}

@keyframes pulse-dot {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.8);
    }
}

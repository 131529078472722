@font-face {
    font-family: 'Inter-Bold';
    src: local('Inter-Bold'), url('./Inter-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Medium';
    src: local('Inter-Medium'), url('./Inter-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Regular';
    src: local('Inter-Regular'), url('./Inter-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: local('Inter-SemiBold'), url('./Inter-SemiBold.ttf') format('truetype');
    font-display: swap;
}

.type-title {

    &_title {
        display: flex;
        align-items: center;
        color: #24242E;
        font-family: 'Inter-Bold';
        font-size: 24px;
    }

    &_subtitle {
        color: #9CA7B5;
        font-family: 'Inter-Bold';
        font-size: 24px;
    }
}

.type-back {
    position: relative;
    min-height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    background-color: #FFFFFF;

    &_button-back {
        position: absolute;
        left: 0;
        height: 100%;
        display: flex;
        align-items: center;

        &_title {
            padding: 0 6px;
            color: #3D3D3D;
            font-family: 'Inter-Regular';
            font-size: 16px;
        }
    }

    &_title {
        color: #333333;
        font-family: 'Inter-SemiBold';
        font-size: 18px;
    }
}

.type-navbar {
    height: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;

    &_icon {
        margin-right: 8px;
    }

    &_title {
        display: flex;
        align-items: center;
        color: #24242E;
        font-family: 'Inter-Bold';
        font-size: 24px;
    }

    & > div {
        flex: 1;
    }

    & > a {
        transition: all 100ms;

        & svg {
    
            &:hover {
                transform: scale(1.2);
            }
        }

        &:last-of-type {
            margin-left: 20px;
        }
    }
}

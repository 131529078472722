.container {
    display: flex;
    margin-bottom: 18px;
}

.image {
    height: 100px;
    width: 100px;
    display: block;
    overflow: hidden;
    object-fit: cover;
    object-position: center center;
    border-radius: 15px;
    box-shadow: 0px 5px 10px rgba(102, 137, 163, 0.5);
}

.info {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 16px;

    &_category {
        align-self: flex-start;
        padding: 4px 8px;
        border-radius: 6px;
        background-color: #ECF0F4;
        color: #24242E;
        font-family: 'Inter-SemiBold';
        font-size: 14px;
    }

    &_title {
        margin-top: 4px;
        padding-left: 4px;
        color: #24242E;
        font-family: 'Inter-SemiBold';
        font-size: 16px;
    }

    &_weight {
        flex: 1;
        display: flex;
        align-items: flex-end;
        color: #9CA7B5;
        font-family: 'Inter-Medium';
        font-size: 14px;
    }
}

.price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    & > p {
        color: #24242E;
        font-size: 18px;
        font-family: 'Inter-SemiBold';
    }
}

.button {
    
    &-remove {

        &_icon {
            height: 30px;
            width: 30px;
            padding: 7px;
            border-radius: 50%;
            background-color: #ECF0F4;
            cursor: pointer;
        }
    }
}

.container {
    padding: 10px 10px;
    color: #9CA7B5;
    font-family: 'Inter-Medium';
    font-size: 18px;

    &-active {
        margin: 0 10px;
        padding: 10px 0 8px 0;
        border-bottom: 2px solid #2868C8;
        color: #24242E;
        font-family: 'Inter-Medium';
        font-size: 18px;
    }
}

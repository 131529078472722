.container {
    min-height: 100%;
    width: 100%;
    max-width: 345px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding-top: 16px;
}

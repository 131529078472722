.container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 1;
    background-color: #FFFFFF;
    touch-action: none;

    transition: opacity 1s 2s;
}

.image {
    transition: opacity 1s;
}

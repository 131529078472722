.container {
	height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    height: 30px;
    width: 30px;
    border: 4px solid #D8E7FE;
    border-top: 4px solid #2868C8;
    border-radius: 50%;

    animation: spinAnimation 2s linear infinite;
}

@keyframes spinAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

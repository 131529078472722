.container {
    justify-content: space-between;
    align-items: center;
    padding: 100px 16px 50px 16px;
}

.backgroundContainer {
    position: absolute;
    top: 0;
    left: 0;
}

.backgroundImage {
    transition: opacity 0;
}

.logoContainer {
    padding-top: 100px;
    overflow: visible;
    z-index: 2;
    border-radius: 20px;
}

.logoImage {
    position: absolute;
    max-width: 188px;
    border-radius: 20px;
    height: auto;
}

.footer {
    max-width: 325px;
    margin-top: 30px;
    z-index: 2;
    opacity: 0;
    text-shadow: 1px 1px #333333;

    transition: opacity 300ms;

    &_title {
        margin-bottom: 10px;
        color: #FFFFFF;
        font-family: 'Inter-Regular';
        font-size: 24px;

        &-client {
            font-family: 'Inter-Bold';
        }
    }

    &_subtitle {
        margin-bottom: 30px;
        color: #FAFAFA;
        font-family: 'Inter-Regular';
        font-size: 14px;
    }

    &_button {
        min-height: 55px;
        width: 100%;
        border-radius: 10px;

        transition: all 100ms;

        &:active {
            opacity: 0.7;
        }

        &-continue {
            background-color: #2868C8;
            color: #FFFFFF;
            font-family: 'Inter-SemiBold';
            font-size: 18px;

            &:hover {
                color: #2868C8;
                background-color: #D8E7FE;
            }
        }
    }
}

.overlay {
    padding-top: 33.3333333333vh;
}

.modal {
    display: flex;
    flex-direction: column;
    padding: 32px 16px 0 16px;

    &_title {

        &-client {
            margin-bottom: 25px;
            color: #24242E;
            font-family: 'Inter-SemiBold';
            font-size: 22px;
        }
    }

    &_subtitle {
        margin-bottom: 16px;
        color: #9CA7B5;
        font-family: 'Inter-Medium';
        font-size: 14px;
    }
}
